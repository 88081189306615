import React from 'react';
export default function Bio() {
  return (
    <>
      <div className="md:w-1/2 md:pr-12 md:py-8 md:border-r md:border-b-0 mb-10 md:mb-0 pb-10 border-b border-gray-200">
        <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">Neal Morris</h1>
        <p className="leading-relaxed text-base">
          Software engineer based in London. Mostly ramble on about my interests and past experiences in the industry.
        </p>
        <br />
        <div className="md:flex">
          <ol style={{ listStyle: 'inside', listStyleImage: 'initial' }}>
            <li>
              Domain driven design
            </li>
            <li>
              Distributed systems
            </li>
            <li>
              DevOps
            </li>
            <li>
              TDD
            </li>
          </ol>
        </div>
      </div>
    </>
  )
}