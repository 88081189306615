import { PostViewModel } from "./models";
import { postPreviews } from "./postPreviews";

class LocalBlogPostClient implements BlogPostClient {

  public getPosts(): PostViewModel[] {
    return postPreviews.map(preview => ({
      ...preview,
      getContentBlob: () => this.getPostContentBlob(preview.urlSlug)
    }));
  }

  private async getPostContentBlob(urlSlug: string): Promise<string> {
    const { content } = await import(`../../blobs/${urlSlug}.json`).then(importRes => importRes.default);
    return content;
  }
}


class ProductionBlogPostClient implements BlogPostClient {

  public getPosts(): PostViewModel[] {
    return postPreviews.map(preview => ({
      ...preview,
      getContentBlob: () => this.getPostContentBlob(preview.urlSlug)
    }));
  }


  private async getPostContentBlob(urlSlug: string): Promise<string> {
    const { content } = await fetch(`/blobs/${urlSlug}.json`, { credentials: "same-origin", mode: "no-cors" }).then(res => res.json());
    return content;
  }
}


export function initialiseBlogClient(): BlogPostClient {
  console.log(process.env.NODE_ENV);
  switch (process.env.NODE_ENV) {
    case "development":
      return new LocalBlogPostClient();
    case "production":
      return new ProductionBlogPostClient();
    default:
      throw new Error('unhandled case');
  }
}




export interface BlogPostClient {
  getPosts(): Array<PostViewModel>;
}