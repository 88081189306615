
import React, { useMemo } from 'react';
import './App.css';
import './index.css';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Home from './Home';

import Post from './posts/Post';
import { QueryClient, QueryClientProvider, } from 'react-query';
import { initialiseBlogClient } from './posts/postClient';

const queryClient = new QueryClient()

const postClient = initialiseBlogClient();

function App() {
  const posts = useMemo(() => postClient.getPosts(), []);
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          <Route path="/" element={<Home posts={posts} />} />
          {/* <Route path="/timeline">
            <Timeline/>
          </Route> */}
          {posts.map(p => (
            <Route key={p.urlSlug} path={`/posts/${p.urlSlug}`} element={<Post post={p} />} />
          ))}

        </Routes>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
