import { PostPreviewViewModel } from "./models";

export const postPreviews: Array<PostPreviewViewModel> = [
  {
    name: "Who are you anyway, and what gives you the right?",
    excerpt: "A brief look at AuthN and AuthZ and the challenges it presents in large-scale systems",
    urlSlug: "who-are-you-and-what-gives-you-the-right",
    category: "AuthN, AuthZ",
    created: new Date("11/06/2021"),
    readingTime: "10 mins",
  },
  {
    name: "What is a workflow anyway?",
    excerpt: "Discovering workflow orchestration and what it brings to the table",
    category: "Service Oriented Architecture, Distributed Systems",
    created: new Date("08/01/2023"),
    readingTime: "8 mins",
    urlSlug: "what-is-a-workflow-anyway",
  },
  {
    name: "Why TDD sucks",
    excerpt: "Yet another TDD blog post.",
    category: "Design Methodologies, Testing Methodologies",
    created: new Date("02/27/2024"),
    readingTime: "10 mins",
    urlSlug: "why-tdd-sucks",
  }
];
