import React, { useEffect, useState } from 'react';
import { PostViewModel } from './models';
import ReactMarkdown from 'react-markdown';
import { PrismAsync as SyntaxHighlighter } from 'react-syntax-highlighter'
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism'

const regex = /[^\w\s]/ig;

const getSafeHeadingLink = ({ content }: { content: React.ReactNode }) => {

  const safeId = content?.toString()
    .replace(regex, "")
    .replaceAll(" ", "-")
    .toLowerCase() ?? (Math.random() * 100).toString();
  return safeId;
}

export default function Post({ post }: { post: PostViewModel }) {
  const [content, setContent] = useState("");

  useEffect(() => {
     post.getContentBlob().then((res) => setContent(res))
  }, [post])

  return (
    <section className="text-gray-600 body-font overflow-hidden">
      <div className="container px-5 py-24 mx-auto">
        <h2 id="post-start" className="text-4xl text-center">{post.name}</h2>
        <br />
        <hr className="space-y-6" />
        <article style={{ margin: '1%' }}>
          <>

            {content && (
              <ReactMarkdown
                className="prose prose-xl justify-center max-w-none"
                children={content}
                components={{
                  h1: ({ node, children, ...props }) => (<h2 id={getSafeHeadingLink({ content: children })} {...props}>{children}</h2>),
                  h2: ({ node, children, ...props }) => (<h3 id={getSafeHeadingLink({  content: children  })} {...props}>{children}</h3>),
                  h3: ({ node, children, ...props }) => (<h4 id={getSafeHeadingLink({  content: children  })} {...props}>{children}</h4>),
                  h4: ({ node, children, ...props }) => (<h5 className='prose' id={getSafeHeadingLink({  content: children  })} {...props}>{children}</h5>),
                  h5: ({ node, children, ...props }) => (<h6 className='prose' id={getSafeHeadingLink({  content: children  })} {...props}>{children}</h6>),
                  code(props) {
                    const {children, className, node, ...rest} = props;
                    const [, language] = /language-(\w+)/.exec(className || '') ?? [];
                    
                    return language ? (
                      <SyntaxHighlighter
                        // {...rest}
                        children={String(children).replace(/\n$/, '')}
                        style={dracula}
                        language={language}
                        PreTag="div"
                      />
                    ) : (
                      <code className={className} {...props}>
                        {children}
                      </code>
                    )
                  }
                }}
              />
            )}

            <a href='#post-start' role="button" style={{ 'position': 'fixed' }} type="button" data-mdb-ripple="true" data-mdb-ripple-color="light" className="inline-block p-3 bg-red-600 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out bottom-5 right-5">
              <svg aria-hidden="true" focusable="false" data-prefix="fas" className="w-4 h-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M34.9 289.5l-22.2-22.2c-9.4-9.4-9.4-24.6 0-33.9L207 39c9.4-9.4 24.6-9.4 33.9 0l194.3 194.3c9.4 9.4 9.4 24.6 0 33.9L413 289.4c-9.5 9.5-25 9.3-34.3-.4L264 168.6V456c0 13.3-10.7 24-24 24h-32c-13.3 0-24-10.7-24-24V168.6L69.2 289.1c-9.3 9.8-24.8 10-34.3.4z"></path></svg>
            </a>
          </>
        </article>
      </div>
    </section>
  );
}