import React from 'react';
import { Link } from 'react-router-dom';
import { PostViewModel } from '.';


export default function Preview({ post }: { post: PostViewModel }) {
  return (
    <>
      <div className="py-8 flex flex-wrap md:flex-nowrap">
        <div className="md:w-64 md:mb-0 mb-6 flex-shrink-0 flex flex-col">
          <span className="font-semibold title-font text-gray-700">{post.category.toUpperCase()}</span>
          <span><i>{post.readingTime} reading time</i></span>
          <span className="mt-1 text-gray-500 text-sm">{post.created.toDateString()}</span>
        </div>
        <div className="md:flex-grow">
          <h2 className="text-2xl font-medium text-gray-900 title-font mb-2">{post.name}</h2>
          <p className="leading-relaxed">{post.excerpt}</p>
          <Link to={`/posts/${post.urlSlug}`} className="text-indigo-500 inline-flex items-center mt-4">
            Read more
            <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path d="M5 12h14"></path>
              <path d="M12 5l7 7-7 7"></path>
            </svg>
          </Link>
        </div>
      </div>
    </>
  )
}