import React, { useMemo } from 'react';
import Bio from './Bio';
import { PostViewModel } from './posts';
import Preview from './posts/Preview';


export default function Home({posts} : {posts: Array<PostViewModel>}) {
  const sortedPosts = useMemo(() => posts.sort((a, b) =>Number(a.created < b.created)), [posts])
  return (
    <>
      <section className="text-gray-600 body-font">
        <div className="container flex flex-wrap px-5 py-24 mx-auto items-center">
          <Bio />
          <div className="flex flex-col md:w-1/2 md:pl-12">
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">Where to find me</h1>
            <nav className="flex flex-wrap list-none -mb-1">
              <li className="lg:w-1/3  mb-1 w-1/2">
                <a target="_blank" href="https://www.linkedin.com/in/neal-morris-71873294/" rel="noreferrer" className="text-gray-600 hover:text-gray-800">LinkedIn</a>
              </li>
              <li className="lg:w-1/3  mb-1 w-1/2">
                <a target="_blank" href="https://github.com/Nealm03" rel="noreferrer" className="text-gray-600 hover:text-gray-800">Github</a>
              </li>
              {/* <li className="lg:w-1/3  mb-1 w-1/2">
                <Link to="/timeline"  className="text-gray-600 hover:text-gray-800">My career history</Link>
              </li> */}

            </nav>
          </div>
        </div>
      </section>
      <section className="text-gray-600 body-font overflow-hidden">
        <div className="container px-5 py-24 mx-auto">
          <div className="-my-8 divide-y-2 divide-gray-100">
            {sortedPosts.map(p => (
              <Preview key={p.urlSlug} post={p} />
            ))}
          </div>
        </div>
      </section>
    </>
  )
}